
import { Options, Vue } from "vue-class-component";
import { INote } from "@/types/Note";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import moment from "moment";
import PaginationComponent from "@/components/PaginationComponent.vue";
import Filter from "@/components/Filter.vue";
import { IEmployeeList } from "@/types/EmployeeList";

@Options({
  name: "Notes",
  components: {
    Breadcrumb,
    PaginationComponent,
    Filter,
  },
})
export default class Notes extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  notes: INote[] = [];
  page = 0;
  perPage = 15;
  totalCount = 0;
  currentPage = 0;
  employeeId = 0;
  crumbs: any = ["Dashboard", "Employees", "Notes"];

  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  savedSort = null;
  async sort(sortBy: any) {
    this.savedSort = sortBy;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/notes?SortBy=${sortBy}&PageIndex=${this.currentPage}&PageSize=${this.perPage}&EmployeeId=${this.$route.params.id}`,
        false
      )
      .then((response: any) => {
        this.notes = response.content.items;
        this.totalCount = response.content.totalCount;
      });
  }

  goToPage(page: number) {
    this.currentPage = page;
    return this.workForceService
      .get<INote[]>(
        `/notes?PageIndex=${page}&PageSize=${this.perPage}&EmployeeId=${this.$route.params.id}`,
        false
      )
      .then((response: any) => {
        return (this.notes = response.content.items);
      });
  }

  previewText(text: string, limit: number) {
    if (text.length > 60) {
      return text.substr(0, limit);
    }
    return text;
  }

  async initialize(empId: any) {
    await this.workForceService
      .get<INote[]>(
        `/notes?PageIndex=${this.page}&PageSize=${this.perPage}&EmployeeId=${empId}`,
        false
      )
      .then((response: any) => {
        this.totalCount = response.content.totalCount;
        this.notes = response.content.items;
      });
  }

  async created() {
    this.employeeId = +this.$route.params.id;
    await this.initialize(this.$route.params.id);
  }

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  formatDate(leaveDate: any) {
    return moment(new Date(leaveDate)).format("Do-MMM-YYYY");
  }
}
